<template>
  
    <header class="fixed-top bg-white">

        <section class="top-header fixed-top">
            <div class="left-bg-header"></div>
        </section>

        <section class="d-flex justify-content-end pt-2">
            <a class="mr-4">
                <img src="../../assets/images/uk.svg" alt="country" class="img-flag">
            </a>
            <a>
                <img src="../../assets/images/id.svg" @click="setUrl" alt="country" class="img-flag">
            </a>
        </section>

        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container-fluid">
                <a class="navbar-brand"> <img src="../../assets/images/logo.jpg" alt="Logo" class="bag-logo"> </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div class="navbar-nav ms-auto text-center">
                        <div class="mydivider"></div>
                            <a class="nav-link"> <router-link to="/home" class="mylink">Home</router-link> </a>
                        <div class="mydivider"></div>
                            <a class="nav-link"> <router-link to="/about_us" class="mylink">About Us</router-link> </a>
                        <div class="mydivider"></div>
                            <a class="nav-link"> <router-link to="/service" class="mylink">Service</router-link> </a>
                        <div class="mydivider"></div>
                            <a class="nav-link"> <router-link to="/news" class="mylink">News</router-link> </a>
                        <div class="mydivider"></div>
                            <a class="nav-link"> <router-link to="/career" class="mylink">Career</router-link> </a>
                        <div class="mydivider"></div>
                            <a class="nav-link"> <router-link to="/eprocurement" class="mylink">E-proc</router-link> </a>
                        <div class="mydivider"></div>
                            <a class="nav-link"> <router-link to="/contact_us" class="mylink">Contact Us</router-link> </a>
                        <div class="mydivider"></div>
                    </div>
                </div>
            </div>
        </nav>

    </header>

</template>

<script>
export default {
    name: 'NavbarEn',
    props: [
        'url',
    ],
    methods: {
        setUrl () {
            this.$router.push(this.url)
        },
    }
}
</script>

<style scoped>
    .bag-logo {
        height: 55px;
        width: auto;
    }

    @media screen and (max-width: 1024px) {
        .bag-logo {
            height: 30px;
            width: auto;
        }
    }

    .img-flag {
        width: 37px;
        height: auto;
    }

    @media screen and (max-width: 1024px) {
        .img-flag {
            width: 25px;
        }
    }

    .top-header {
        position: relative;
        background: #0158a7;
        padding-bottom: 17px;
    }

    .left-bg-header {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: #ea2e23;
        width: 20%;
    }

    .mylink {
        font-size: 20px;
        font-weight: 500;
        text-decoration: none;
        color: #0158a7;
    }

    .mylink:hover {
        color: #ea2e23;
    }

    @media screen and (max-width: 720px) {
        .mylink {
            font-size: 15px;
            font-weight: 300;
        }
    }

    .mydivider {
        width: 1px;
        height: 50px;
        background-color: #0158a7;
        margin-left: 5px;
        margin-right: 5px;
    }

    @media screen and (max-width:1024px) {
        .mydivider {
            display: none;
        }
    }
</style>